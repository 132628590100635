import React,{useEffect, useRef} from "react"
import { Container, Col, Row } from "react-bootstrap"
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
import LogoMuni from "../assest/logomuni.WebP";
import LogoCultura from "../assest/Logo-cultura.png";
import LogoSecretaria from "../assest/Logo-secretaria.png";
import { Link } from "react-router-dom";

const Footer = (props) => {


    return (
        <Container className="mt-5" id="footer">
            <Col className="redes-footer">
                <div className="redes-footer--icons">
                    <a href="https://www.facebook.com/Cultura-San-Antonio-de-Areco-105629328085123" target="_blank" rel="noopener noreferrer"><FaFacebookF size={30} fill="#fff" /></a>
                    <a href="https://www.instagram.com/culturaareco/" target="_blank" rel="noopener noreferrer"><FaInstagram size={30} fill="#fff" /></a>

                </div>

            </Col>
            <Row className="mt-5">

                <Col sm={12} md={6} lg={6} xl={6} xxl={6} >
                    <Row>
                        <Col>
                            <a href="https://areco.gob.ar" target="_BLANK" rel="noreferrer">
                                <img src={LogoCultura} alt="Logo muni" />
                            </a>
                        </Col>
                        <Col>
                            <a href="https://areco.gob.ar" target="_BLANK" rel="noreferrer">
                                <img src={LogoSecretaria} alt="Logo muni" />
                            </a>
                        </Col>
                        <Col>
                            <a href="https://areco.gob.ar" target="_BLANK" rel="noreferrer">
                                <img src={LogoMuni} alt="Logo muni" />
                            </a>
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <h3>Archivo Histórico Municipal</h3>

                    <p className="text-center">Dirección : Blvd.Héroes de Malvinas 66</p>
                    <p className="text-center">+54 2326 456202 / 454722</p>
                    <p className="text-center">
                        
                        <a href="mailto:cultura@areco.gob.ar"  >archivohistoricoareco@gmail.com</a>
                    </p>


                </Col>
            </Row>
        </Container>
    )
}
export default Footer