import React from "react";
import { Col, Container } from "react-bootstrap";
import Calesita from "../components/carrousel"
import { cargador } from "../datos/cargador de fotos";


const Fototeca=()=>{
    const fotos=cargador(require.context('../assest/fototeca', false, /\.(png|jpeg|jpg|svg|WebP)$/));
    return(
        <Container>
        <div className="mt-5">
                <p>
                El Archivo posee una importante recopilación de fotografías de lugares, instituciones, eventos y personalidades de San Antonio de Areco digitalizadas en alta calidad. 
                </p>
        
            </div>
        <Col>
        <Calesita fotos={fotos}/>
        </Col>
        </Container>
    )
}
export default Fototeca