import logo from './logo.svg';
import './App.css';
import Navegacion from './components/Navegacion';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
      <Navegacion/>
      <Footer/>
    </div>
    
  );
}

export default App;
