import React from "react";
import { Route, Routes,BrowserRouter, Link} from "react-router-dom";
import Home from "../Pages/Home";
import Archivo from "../Pages/Archivo";
import FondosyColecciones from "../Pages/FondosyColecciones";
import Fototeca from "../Pages/Fototeca";
import OtrosArchivos from "../Pages/OtrasColecciones";
import Footer from "./Footer";
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from "../assest/logo3.png";

const Navegacion = () => {
   
    return (
        <>
       <div className="fotoportada"></div>
       <BrowserRouter>
        <Navbar bg="light" expand="lg">
            <Container>
            
                <Navbar.Brand as={Link} to="/inicio">
                    <img src={logo} alt="logo" height="150px" width="150px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/inicio"><Button> INICIO</Button></Nav.Link>
                        <Nav.Link as={Link} to="/archivo"><Button>EL ARCHIVO</Button> </Nav.Link>
                        <Nav.Link as={Link} to="/fondos"><Button>FONDOS Y COLECCIONES </Button> </Nav.Link>
                        <Nav.Link as={Link} to="/fototeca"><Button>FOTOTECA </Button> </Nav.Link>
                        <Nav.Link as={Link} to="/otrosarchivos"><Button>OTROS ARCHIVOS</Button> </Nav.Link>
                        
                        <Nav.Link href="#footer"><Button>CONTACTO</Button></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route  path="/inicio" element={<Home/>}/>
            <Route  path="/archivo" element={<Archivo/>} />
            <Route  path="/fondos" element={<FondosyColecciones/>}/>
            <Route  path="/fototeca" element={<Fototeca/>}/>
            <Route  path="/otrosarchivos" element={<OtrosArchivos/>}/>
        </Routes>
        </BrowserRouter>
        </>
    )
}
export default Navegacion 