import React from "react";
import { Container, Table } from "react-bootstrap";
import datos from "../datos/fondos.json"

const Fondos = () => {
    return (
        <Container>
            <h1>Fondos y colecciones</h1>
            <p>Nuestro Archivo se encuentra organizado en fondos y colecciones que contienen documentación pública y privada de los siglos XIX, XX y XXI. En la aplicación web 
               <a href="https://atomweb.areco.gob.ar/index.php/archivo-hist-rico-municipal-de-san-antonio-de-areco-juan-h-vieytes" target="_blank" rel="noreferrer" className="link-atom"> Atom </a> 
                podrá conocer el catálogo detallado, realizar búsquedas específicas y acceder a los enlaces de los documentos digitalizados.</p>
            <Table striped bordered hover className="mt-5">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre del Fondo</th>
                        <th>Digitalización</th>
                        <th>Atom</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((data, i) => {
                        return (
                            <tr>
                                <td>{data.codigo}</td>
                                <td>
                                    <h4>{data.nombreFondo}</h4>
                                    <p>{data.descripcion}</p>
                                </td>
                                <td>{data.estado}</td>
                                <td><a href={data.link} target="_blank" rel="noopener noreferrer">Ver</a></td>
                            </tr>
                        )
                    })}



                </tbody>
            </Table>
        </Container>
    )
}
export default Fondos