import React from 'react';
import {Carousel}from 'react-bootstrap';


const  carrousel=(props)=>{
  

  return(
        <>
      
       <Carousel fade interval={3000}  >
          {props.fotos.map((data,i)=>{
            return(
              <Carousel.Item key={i}  >
              <img alt="" src={data} className="foto-tamanio"></img>
          
            </Carousel.Item>
            )
          }
          )}
        
          
           
        
        
      </Carousel>
</>
    )
}
export default  carrousel;