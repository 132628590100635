import React from "react";
import { Container, Table } from "react-bootstrap";
import datos from "../datos/OtrosFondos.json"

const OtrasColecciones = () => {
    return (
        <Container>
            <div className="texto-otros">
            <h1>Otros Archivos</h1>
            <p id="parrfo-sin-margin">Estamos trabajando en el ordenamiento y digitalización de colecciones pertenecientes a particulares y a diversas instituciones de San Antonio de Areco.</p>
            <p>El objetivo es crear una red de archivos de manera digital a fin de visibilizar los diferentes acervos patrimoniales y facilitar el acceso a la documentación.</p>
            </div>
            
            <Table striped bordered hover className="mt-5">
                <thead>
                    <tr>
                        <th>Logo</th>
                        <th>Nombre del Fondo</th>
                        <th>Digitalización</th>
                        <th>Atom</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((data, i) => {
                        return (
                            <tr>
                                <td><img src={data.codigo} alt="" width="100px" height="100px"/></td>
                                <td>
                                    <h4>{data.nombreFondo}</h4>
                                    <p>{data.descripcion}</p>
                                </td>
                                <td>{data.estado}</td>
                                <td><a href={data.link} target="_blank" rel="noopener noreferrer">Ver</a></td>
                            </tr>
                        )
                    })}



                </tbody>
            </Table>
        </Container>
    )
}
export default OtrasColecciones