import React from "react";
import { Container } from "react-bootstrap";

const Archivo = () => {
    return (
        <Container>
            <div className="mt-5">
                <p>
                    El día 12 septiembre de 1950 se sancionó la Ordenanza Nº 90 que creó la Comisión Municipal Recopiladora de Antecedentes Históricos y Tradicionales con el fin de:</p>
                <p><i>“Reunir y estudiar los antecedentes históricos y tradicionales de aquellos lugares de nuestro pueblo que por hallarse vinculados a hombres o a hechos de la historia patria o lugareña, o por señalarse en ellos la fisonomía peculiar que caracteriza a San Antonio de Areco, merezcan ser objetos de recuerdo para las generaciones actuales y futuras, y aconsejar los medios para que perdure el conocimiento de aquellos hombres, circunstancias o hechos tradicionales a los que se hallan vinculados.”</i></p>
                <p>Unos meses después de la sanción de la ordenanza y su posterior reglamentación, la Comisión elaboró un pedido formal dirigido al Concejo Deliberante para crear un Archivo Histórico. En el proyecto que presentaron el 25 de abril de 1952 los miembros de la Comisión plantearon la necesidad de crear un<i> “archivo histórico municipal de San Antonio Areco, destinado a reunir y organizar fondos documentales existentes en la municipalidad.”</i></p>
                <p>El 11 de junio del mismo año el proyecto de ordenanza de creación del Archivo Histórico Municipal de San Antonio de Areco fue aprobado.</p>
                <p>Recién para el período 1960-1965 se comienza con las tareas de selección, agrupación, ordenamiento y codificación del acervo documental llevado a cabo por el encargado de la institución en ese momento, Fermín C. Pereyra.</p>
                <p>En el año 1969, el Intendente Municipal Ángel Alonso mediante decreto, encomendó la custodia y preservación del Archivo Histórico Municipal a la Biblioteca Popular Belgrano.</p>
                <p>Dicha Institución tuvo a su cargo la guarda de la documentación hasta el año 2015 cuando se realizó el traspaso de la colección de regreso a la esfera municipal. Desde ese momento el Archivo Histórico Municipal “Juan H. Vieytes” funciona en el edificio que ocupa el Museo de la Ciudad y Centro Cultural “Usina Vieja.”</p>
                <p>En el año 2022, con motivo del 70º aniversario de la institución, se finalizó con la organización, catalogación y digitalización integral del acervo patrimonial en aras de garantizar su conservación y facilitar su acceso.</p>
            </div>


        </Container>
    )

}

export default Archivo 