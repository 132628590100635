import React from "react";
import { Container } from "react-bootstrap";

const Home = () => {
        return (
                <Container>
                        <div className="mt-5">
                                <p>
                                        Bienvenido/a al sitio del Archivo Histórico Municipal “Juan H. Vieytes” de San Antonio de Areco. Aquí podrás acceder a los fondos y colecciones que forman parte del acervo patrimonial de la localidad y de la región.
                                </p>
                                <p>
                                        La sostenibilidad futura de los archivos se basa en su capacidad de conservar la integridad de las colecciones que se encuentran bajo su tutela. Pero la protección por sí sola no garantiza que su misión pueda seguir siendo relevante para las generaciones futuras ni que los patrimonios que custodian sean valorados por la comunidad.
                                </p>
                                <p>
                                        Por ello generamos espacios para que la comunidad pueda acceder a la documentación histórica como paso previo para su apropiación y recirculación. Facilitar el libre acceso a la información de los archivos permite aprovechar las potencialidades de la inteligencia colectiva, promover la participación en la construcción de la memoria de la comunidad y de nuevas miradas sobre el pasado.
                                </p>
                                <p>
                                        Te invitamos a conocer nuestro archivo y a consultarlo.

                                </p>
                        </div>
                </Container>
        )

}

export default Home

